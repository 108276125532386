/* Example CSS for MultiSelect styling */
.p-multiselect {
  width: 100%;
  height: 90%;
}
.p-multiselect-token {
  z-index: 2;
}
.p-multiselect-label {
  font-size: 12px;
  font-weight: normal;
  color: #495057;
}
.ol .ul {
  padding-left: 0px;
}
.p-multiselect-item-group {
  padding: 0px;
}
.p-multiselect-panel {
  padding: 0px;
}
.p-multiselect-items {
  padding: 5px;
}
.p-multiselect-trigger {
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  background-color: #fff;
}
.p-multiselect-filter-container {
  border: 1px solid;
  border-color: #c1c8cd;
}
.p-multiselect-trigger-icon {
  margin-top: -3px;
}

.p-multiselect-items-wrapper {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
}

.p-multiselect-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.p-multiselect-item:hover {
  background-color: #f8f9fa;
}

.p-multiselect-token {
  margin: 2px;
  font-size: 0.9rem;
  background-color: #ffffff;
  color: #000000;
}

.p-multiselect-trigger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-multiselect-trigger-icon svg {
  margin-top: 2px;
}

.p-dropdown {
  width: 100%;
  height: 90%;
}

.p-dropdown-label {
  font-size: 12px;
  font-weight: normal;
  color: #495057;
}

.p-dropdown-trigger {
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  background-color: #fff;
}

.p-dropdown-trigger-icon {
  margin-top: -3px;
}

.p-dropdown-items-wrapper {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
}

.p-dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.p-dropdown-item:hover {
  background-color: #f8f9fa;
}

.p-dropdown-trigger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dropdown-trigger-icon svg {
  margin-top: 1px;
}
svg {
  height: 14px !important;
}
.rs-btn-link {
  color: var(--white1);
}

.hidden-checkbox .p-multiselect-header {
  display: none;
}

.rs-picker-error > .rs-input-group {
  border-color: #ced4da;
}

/* .rs-picker-clean .rs-btn-close{
  display: none !important;
} */
.rs-picker-daterange .rs-input-group-addon .rs-btn-close {
  display: none !important;
}

.p-multiselect .p-multiselect-panel {
  min-width: 10%;
  max-width: 100%;
  width: 20%;
  left: unset !important;
}
